module.exports = {
    aws_table_name: 'user_logins',
    aws_table_name2: 'user_count',
    aws_local_config: {
      //Provide details for local configuration
    },
    aws_remote_config: {
      accessKeyId: 'AKIAWTQVNSGDICNO7QEE',
      secretAccessKey: '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',
      region: 'us-east-2',
    }
};
