module.exports = {
    aws_table_name: 'user_logins',
    aws_table_name2: 'user_count',
    aws_local_config: {
      //Provide details for local configuration
    },
    aws_remote_config: {
      accessKeyId: 'AKIAWTQVNSGDCO6SAFWX',
      secretAccessKey: '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',
      region: 'us-east-2',
    }
};
